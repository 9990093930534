import { EndCustomer, InvoicePdfTemplate, useGetInvoicePdfTemplatesPaginatedLazyQuery } from "@api/graphql/generated/generated-types";
import React, { useEffect } from "react";
import EditCustomerForm from "@src/endcustomers/components/EditCustomerForm";
import { Loading } from "@src/common/components";
import { useErrorToast } from "@src/common/hooks/useErrorToast";

export interface CustomerWidgetState {
  data: {
    endCustomer: EndCustomer | null | undefined;
  };
  loading: boolean;
  error: any;
}

type Props = {
  endCustomer: EndCustomer | null | undefined;
};

function CustomerWidget({ endCustomer }: Props) {
  const [
    getInvoicePdfTemplates,
    {
      data: invoicePdfTemplatesData,
      loading: invoicePdfTemplatesLoading,
      error: invoicePdfTemplatesError,
    },
  ] = useGetInvoicePdfTemplatesPaginatedLazyQuery();
  const showError = useErrorToast();

  useEffect(() => {
    if (endCustomer) {
      getInvoicePdfTemplates({
        variables: {
          courierId: endCustomer.courierId,
          limit: 100,
          after: 0,
        },
        fetchPolicy: "network-only",
      }).catch(showError);
    }
  }, [endCustomer]);

  if (endCustomer && invoicePdfTemplatesData) {
    const invoicePdfTemplates = invoicePdfTemplatesData?.invoicePdfTemplatesPaginated?.edges?.map(edge => edge?.node)
        .filter((node): node is InvoicePdfTemplate => node !== null && node !== undefined) || [];

    return (
      <Loading loading={invoicePdfTemplatesLoading || false}>
        <div className="p-3 h-full bg-white dark:bg-gray-800 border rounded-2xl">
          <span className="font-semibold mb-2">Customer</span>
          <div className="rounded-lg border shadow-md p-2 pt-2">
            <EditCustomerForm
              endCustomer={endCustomer}
              invoicePdfTemplates={invoicePdfTemplates}
              maxWidth={"100%"}
            />
          </div>
        </div>
      </Loading>
    );
  }
  return null;
}

export default CustomerWidget;
